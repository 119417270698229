import Debug from 'debug';

import React, { useCallback, useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';

import ItemPageHeaderContent from '~/app/components/ItemPage/ItemPageHeaderContent';
import ItemPageHeroContent from '~/app/components/ItemPage/ItemPageHeroContent';
import ItemPageMetadata from '~/app/components/ItemPage/ItemPageMetadata';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import useSelectArtists from '~/app/lib/hooks/useSelectArtists';
import ArtistNames from '~/app/components/ItemPage/ArtistNames';
import useSelectTrack from '~/app/lib/hooks/useSelectTrack';
import { PageTrackerContext } from '~/app/components/Page';
import ItemPage from '~/app/components/ItemPage';
import { useI18n, useI18nStatic } from '~/app/lib/i18n';
import { ItemTypes, PageTypes } from '~/types';
import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';
import useHash from '~/app/lib/hooks/useHash';
import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';
import claimPageDialogLocales from '~/locales/fragments/claimPageDialog';
import addUserPageApi from '~/app/lib/songwhipApi/users/addUserPageApi';
import { useDispatch } from '~/app/lib/store/redux';
import { useAppToast } from '~/app/components/NextApp/lib/CoreUi';
import { toObservedLoadingComponent } from '~/app/components/ItemPage/LoadingObserver';
import { useTracker } from '~/app/lib/tracker/useTracker';
import addItemToAccountAction from '~/app/lib/store/accounts/actions/addItemToAccountAction';

import useTrackItemContext from './useTrackItemContext';
import toStructuredData from './toStructuredData';
import useTrackChecks from './useTrackChecks';

const debug = Debug('songwhip/ItemPageTrack');

const OrchardLegalFooterDynamic = dynamic(
  () => import('~/app/components/ItemPage/OrchardLegalFooter'),
  { ssr: true }
);

const VideosSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/VideosSection')
);

const MerchSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/MerchSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const ShowsSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/ShowsSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const PAGE_SECTION_COMPONENTS = {
  [PageSectionTypes.VIDEOS]: VideosSectionDynamic,
  [PageSectionTypes.MERCH]: MerchSectionDynamic,
  [PageSectionTypes.SHOWS]: ShowsSectionDynamic,
};

const ItemPageTrack = ({ trackId }: { trackId: number }) => {
  const { t } = useI18n();

  const { t: tClaimPageDialog } = useI18nStatic<'claimPageDialog'>(
    claimPageDialogLocales
  );

  const user = useFetchSessionUser();
  const { setHashParam } = useHash();
  const track = useSelectTrack(trackId)!;
  const itemContext = useTrackItemContext({ track })!;
  const isLargeScreen = useIsLargeScreen();
  const { showArtistIds } = itemContext!.config;
  const artists = useSelectArtists(showArtistIds);
  const artistNames = artists?.map(({ name }) => name).join(', ') ?? '-';
  const dispatch = useDispatch();
  const showToast = useAppToast();
  const { trackEvent } = useTracker();
  const { isLoggedIn } = useFetchSessionUser();

  useTrackChecks(track);

  // Try to auto-claim the track page if the user already owns
  // one of the artist on the track
  useEffect(() => {
    // We only auto-claim for Orchard users
    if (user.userAccounts) {
      debug('Trying to auto-claim track');

      const userAccountIds = user.userAccounts?.map(({ id }) => id);

      const userOwnsTrack = Boolean(
        userAccountIds &&
          track.ownedByAccountIds?.find((id) => {
            return userAccountIds.includes(id);
          })
      );

      if (userOwnsTrack) {
        debug('Noop: The user already owns the track');
        return;
      }

      const userOwnsArtist = Boolean(
        userAccountIds &&
          artists?.find((artist) => {
            return artist.ownedByAccounts?.find(({ id }) => {
              return userAccountIds.includes(id);
            });
          })
      );

      if (!userOwnsArtist) {
        debug('Noop: The user does not own any of the artist on the track');
        return;
      }

      // We call `addUserPage` with `dryRun:true` to figure out
      // if the user can claim the page, if so we add the page to
      // the user's account that's able to claim it and show a
      // success toast, otherwise we silently ignore the errors.
      // The user will still be able to try to claim the page from
      // the action menu and see the reason why they cannot claim the page.
      addUserPageApi({
        userId: user.userId!,
        itemType: ItemTypes.TRACK,
        itemId: track.id,
        dryRun: true,
      })
        .then(({ account }) => {
          dispatch(
            addItemToAccountAction({
              accountId: account.id,
              itemType: ItemTypes.TRACK,
              itemId: track.id,
              trackEvent,
            })
          )
            .then(() => {
              debug('Track claimed');

              showToast({
                text: tClaimPageDialog('claimPageSuccess'),
                testId: 'claimPageSuccessToast',
              });
            })
            .catch((e) => {
              debug('Error claiming track', e);
            });
        })
        .catch((e) => {
          debug('User cannot claim track', e);
        });
    }
  }, [user, track, artists]);

  return (
    <ItemPage
      key={trackId}
      pageType={PageTypes.TRACK}
      itemContext={itemContext}
      hasContent={!!track.links}
      testId="itemPageTrack"
      displayType="song"
      error={track.error}
      isLoading={!!track.isLoading}
      isOwned={track.isOwned}
      isOrchardPage={track.isOrchardPage}
      pageBrand={track.pageBrand}
      userCanEdit={!!track.userCanEdit}
      pageOwnedByAccountIds={track.ownedByAccountIds}
      trackerContext={useMemo(
        (): PageTrackerContext => ({
          artistId: track.artistId,
          artistName: track.artistName,

          trackId: track.id,
          trackName: track.name,
        }),
        [track]
      )}
      pageSectionComponents={PAGE_SECTION_COMPONENTS}
      heroHeight={isLargeScreen ? '38vh' : '85vw'}
      backgroundHeight={isLargeScreen ? '100vh' : '105vw'}
      shareText={
        track.name &&
        t('item.trackPageMetaTitle', {
          trackName: track.name,
          artistNames,
        })
      }
      pagePath={track.pagePath}
      artistPagePath={track.artistPagePath}
      toActionItems={useCallback(
        ({ sections }) => {
          if (track.userCanEdit) {
            sections[0].push({
              content: t('item.actions.viewInDashboard'),
              href: `/dashboard/song/${track.id}`,
              icon: 'chart',
              testId: 'viewInDashboard',
            });
          } else if (isLoggedIn) {
            sections[0].push({
              // If the user doesn't own the track,
              // they can try to claim it
              content: t('item.actions.claimTrack'),
              icon: 'verified',
              testId: 'claimTrackOption',
              onClick: () => {
                setHashParam({ claimPage: '' });
              },
            });
          }

          if (artists) {
            sections.push(
              artists.map(({ name, pagePath }) => ({
                content: `${name}`,
                href: pagePath,
                icon: 'person',
              }))
            );
          }

          return sections;
        },
        [track, artists]
      )}
      renderHeroContent={useCallback(
        () =>
          isLargeScreen ? (
            <ItemPageHeroContent
              title={track.name}
              subtitle={<ArtistNames artistIds={showArtistIds} />}
            />
          ) : null,
        [isLargeScreen, track]
      )}
      renderHeaderContent={useCallback(
        () =>
          !isLargeScreen ? (
            <ItemPageHeaderContent
              title={track.name}
              subtitle={<ArtistNames artistIds={showArtistIds} />}
            />
          ) : null,
        [track, isLargeScreen]
      )}
      renderFooterContent={useCallback(() => {
        if (track.isOrchardPage) {
          return <OrchardLegalFooterDynamic />;
        }
      }, [])}
      renderContent={useCallback(() => {
        return (
          <ItemPageMetadata
            error={track.error}
            pagePath={track.pagePath}
            itemContext={itemContext}
            isOwned={track.isOwned!}
            defaultTitle={
              track.name &&
              t('item.trackPageMetaTitle', {
                trackName: track.name,
                artistNames,
              })
            }
            defaultImage={track.image}
            defaultDescription={
              track.name &&
              t('item.trackPageMetaDescription', {
                trackName: track.name,
                artistNames,
              })
            }
            // REVIEW: this could be a <StructuredData> component instead
            // of having to pass props down the tree to <PageMetaData>
            // which is getting quite overloaded now
            toStructuredData={(params) =>
              // @ts-ignore
              toStructuredData({
                ...params,
                trackName: track.name,
                artistName: track.artistName,
                artistPagePath: track.artistPagePath,
                // TODO: add artist image (need resolving to full url)
                artistImageUrl: undefined,
              })
            }
          />
        );
      }, [track])}
    />
  );
};

export default ItemPageTrack;
